import React, { useRef } from 'react';
import './App.css';

import Editor from '@monaco-editor/react';

function App() {


  return (
    <div className="App">
      <header className="App-header">

        <div className='py-5 text-center'>
        <a target="_blank" href="https://twitter.com/naklecha/status/1626601907858051073"><button className='m-5 bg-blue-600 text-white p-2 px-5 rounded-3xl font-mono'>View updates on Twitter</button></a>
        <br></br>
          <p className='text-5xl text-center'>Google Colab Copilot</p>
          <p className='text-1xl text-center text-gray-700 font-mono mt-3'>say goodbye to alt tabbing, github copilot implemented on google colab 👋</p>
          <a target="_blank" href="https://bevel-pufferfish-154.notion.site/Getting-started-with-Copilot-7be0d614295a4836b84fb9cf7c909227"><button className='m-5 bg-black text-white p-5 rounded-3xl font-mono'>&lt;/setup guide&gt;</button></a>
        </div>
        <div className='px-5'>
        <video className='m-auto rounded-xl shadow-xl' width="700px" src="demo.mp4" autoPlay loop muted></video>
        </div>
        <br></br><br></br>
        <hr></hr>
        <br></br>
        <div className='px-5 m-auto font-mono w-fit'>
          <p className='text-3xl font-bold mb-2'>Let's get setup...</p>
          <p className='mt-1'>1. Copy the Javascript from <a className='text-blue-600' target="_blank" href='https://github.com/naklecha/google-colaboratory-copilot/blob/main/script.js'>Github</a>.</p>
          <p className='mt-1'>2. Replace "&lt;YOUR OPENAI API KEY&gt;" in the script with your <a className='text-blue-600' target="_blank" src="https://platform.openai.com/account/api-keys">OpenAI API key</a>.</p>
          <p className='mt-1'>3. Open and paste the script on your console on Google Colab. Follow this <a className='text-blue-600' target="_blank" href='https://bevel-pufferfish-154.notion.site/Getting-started-with-Copilot-7be0d614295a4836b84fb9cf7c909227'>setup guide</a>.</p>
          <p className='mt-1'>4. <p className="kbc-button">Shift</p> + <p className="kbc-button">Alt</p> + <p className="kbc-button">Enter</p> on Google Colab.</p>
          <p className='mt-1'>5. 💥 KA-BOOM! 💥</p>
        </div>
        
        <br></br><br></br>
        
      </header>
    </div>
  );
}

export default App;
